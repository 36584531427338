import ApiAuthorzationRoutes from './components/api-authorization/ApiAuthorizationRoutes';
import { Counter } from "./components/Counter";
import { FetchData } from "./components/FetchData";
import { Home } from "./components/Home";
import { Github } from "./components/Github";
import {Timeline} from "./components/Timeline";
import {Contact} from "./components/Contact";
import {Skills} from "./components/Skills";

const AppRoutes = [
  {
    index: true,
    element: <Home />
  },
  /*{
    path: '/counter',
    element: <Counter />
  },
  {
    path: '/fetch-data',
    requireAuth: true,
    element: <FetchData />
  },*/
  {
    path: '/github',
    element: <Github />
  },
  {
    path: '/skills',
    element: <Skills />
  },
  {
    path: '/timeline',
    element: <Timeline />
  },
  {
    path: '/contact',
    element: <Contact />
  }
  /*...ApiAuthorzationRoutes*/
];

export default AppRoutes;
