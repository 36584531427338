import React from "react";
import ReactDOM from "react-dom";
import ReactDC from "react-dom/client";
import {
    Button,
    Dialog,
    DialogHeader,
    DialogBody,
    DialogFooter,
} from "@material-tailwind/react";

export class Modal extends React.Component{
    constructor(props) {
        super(props);
        this.state = {open: true};
    }
    static Open(title = "Dialog", body = "Body", button1 = null, button2 = null) {
        const container = document.getElementById('modal-root');
        //ReactDOM.render(<Modal title={title} body={body} button1={button1} button2={button2} />, container);
        window.mevlutfaitpasconfianceachatgpt = ReactDC.createRoot(container);
        window.mevlutfaitpasconfianceachatgpt.render(<Modal title={title} body={body} button1={button1} button2={button2} />);
    }
    handleClose = () => {
        this.setState({ open: false });
        const container = document.getElementById('modal-root');
        if(window.mevlutfaitpasconfianceachatgpt){
            window.mevlutfaitpasconfianceachatgpt.unmount();
            window.mevlutfaitpasconfianceachatgpt = null;
        }else
            ReactDOM.unmountComponentAtNode(container); // Pour nettoyer après fermeture
    }
    render() {
        return (
            <>
                <Dialog open={this.state.open} onClose={this.handleClose} size="xs">
                    <DialogHeader>{this.props.title}</DialogHeader>
                    <DialogBody>
                        {this.props.body}
                    </DialogBody>
                    <DialogFooter>
                        <Button
                            variant="text"
                            color="red"
                            onClick={this.handleClose}
                            className="mr-1">
                            <span>Cancel</span>
                        </Button>
                        <Button variant="gradient" color="green" onClick={this.handleClose}>
                            <span>Confirm</span>
                        </Button>
                    </DialogFooter>
                </Dialog>
            </>
        );
    }

}