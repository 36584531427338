import React, {Component} from 'react';
import {setNavigation} from "./NavBar";
import './stylesheet/Home.css';
import {Link} from "react-router-dom";
export class Home extends Component {
    static displayName = Home.name;
    
    constructor(props) {
        super(props);
        /*this.state = {songs: [], loading: false}*/
        this.state = {
            messages: ['Développeur informatique', '21 ans', 'Besançon', 'Recherche d\'alternance', 'Etudiant', 'BAC +2', 'Dijon'],
            currentMessageIndex: 0,
        };

        this.intervalId = null;
        setNavigation("Accueil");
    }
    /*componentDidMount() {
        //this.populateSpotifyData();
    }*/
    async populateSpotifyData() {
        const token = null;
        const response = await fetch('spotify/top');
        if (response.status !== 200) {
            console.log(response.status);
            this.setState({songs: [], loading: false});
            return;
        }
        const data = await response.json();
        this.setState({songs: data, loading: false});
    }
    static renderSpotifySongs(songs) {
        console.log(songs);
        if (songs.length === 0)
            return (
                <span className="dark:bg-amber-950 rounded-lg px-8 shadow-xl dark:text-white">
                    An error has occured.
                </span>
            )
        return (
            <div className="grid sm:grid-cols-3 md:grid-cols-4 gap-4 grid-cols-2 py-3">
                {
                    songs.map(song => 
                        <div key={song.name}>
                            <p>
                                <Link to={song.Url} target="_blank">{song.Name}</Link>
                            </p>
                        </div>
                    )
                }
            </div>
        );
    }
    componentDidMount() {
        this.intervalId = setInterval(this.scrollMessages, 2000);
    }

    componentWillUnmount() {
        clearInterval(this.intervalId);
    }

    scrollMessages = () => {
        const { messages, currentMessageIndex } = this.state;
        const nextIndex = (currentMessageIndex + 1) % messages.length;
        this.setState({ currentMessageIndex: nextIndex });
    };
    render() {
        const { messages, currentMessageIndex } = this.state;
        const currentMessage = messages[currentMessageIndex];

        return (
            <div className="landing-page">
                <div className="content">
                    <div className="py-4 text-6xl text-center dark:text-cyan-100">
                        <p>Adel M'haïa</p>
                    </div>
                    <div className="message-animation">{currentMessage}</div>
                </div>
            </div>
        );
        /*let contents = this.state.loading ?
            <p><em>Loading...</em></p> : Home.renderSpotifySongs(this.state.songs)*/
        /*return (
            <>
                <div className="relative">
                    <p className="inline bg-gradient-to-r from-indigo-200 via-sky-400 to-indigo-200 bg-clip-text font-display text-5xl tracking-tight text-transparent">Site web en développement.</p>
                    <span className="block invisible">Le portfolio a été mis à jour le 12/06/2023</span>
                </div>
                <div className="h-screen">
                    <div className="relative h-full">
                        <div className="absolute inset-0">
                            <span className="text-8xl">
                                M'haïa Adel
                            </span>
                            <div className="bg-slate-950 text-amber-50">
                                <div className="border-2">
                                    Spotify: <span>Now</span>
                                </div>
                                <div>
                                    {contents}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bg-slate-800">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam sed urna bibendum, egestas dolor quis, pretium mauris. Aenean ornare risus eu vulputate hendrerit. Proin porta imperdiet leo nec fringilla. Suspendisse sollicitudin mi non molestie egestas. Aenean lobortis fermentum accumsan. Cras sit amet enim elementum, luctus quam et, feugiat diam. Fusce sollicitudin sem ullamcorper ornare scelerisque. In cursus neque enim, id placerat sem porta a. Etiam nec leo quis tellus molestie placerat. Duis iaculis fringilla lorem. Sed semper, libero in luctus fringilla, sapien libero ullamcorper tortor, quis auctor tellus ex quis metus.

                    Nam in ornare erat. Sed accumsan consectetur ornare. Donec eleifend ex at convallis elementum. Mauris odio nibh, tempor eget molestie non, maximus eu lectus. In sed ligula sit amet mauris fermentum ultrices. Quisque arcu eros, dapibus ac nisl at, tempor finibus purus. Proin hendrerit dapibus sagittis. Maecenas eleifend leo nec enim dictum luctus. Sed vitae lacus ut sapien cursus sodales pharetra sit amet metus. Morbi eget augue lacus. Vivamus tempor semper lacus, at tincidunt tellus porta sit amet.

                    Aliquam congue odio odio, vitae auctor libero iaculis id. Etiam dictum ullamcorper elementum. Aenean laoreet metus felis, eu placerat mi vestibulum id. Vivamus eget tincidunt massa. Quisque accumsan risus metus, sed ultrices enim efficitur eu. Morbi eu nisi finibus, pulvinar odio at, blandit arcu. Curabitur et sodales felis, in convallis eros. Mauris cursus urna lobortis, posuere ipsum sit amet, posuere dui. Sed ac orci pharetra, laoreet arcu quis, placerat risus. Curabitur a nunc turpis.

                    Aenean egestas laoreet odio. Quisque nec aliquet magna, a tristique libero. Morbi a blandit metus. Pellentesque ultrices mattis velit nec vehicula. Ut porttitor rhoncus magna ac consectetur. Suspendisse dignissim bibendum egestas. Sed sed nisl vitae turpis hendrerit volutpat dapibus ut sem. Aliquam eget libero rhoncus, blandit lorem ac, interdum nulla. Quisque facilisis finibus vehicula. Aenean pharetra mattis fringilla. Mauris vulputate bibendum risus vitae cursus. Nam pulvinar turpis diam, vel convallis quam aliquet a. Sed tristique tortor nibh, vitae tincidunt nisl bibendum a. Nam posuere eu tortor eu consectetur. Nam pretium in lectus vitae efficitur.

                    Quisque et eros lectus. Nulla vitae vestibulum tellus. Donec molestie eros quis ultricies feugiat. Pellentesque ac volutpat lorem. Proin eu tellus volutpat, dignissim neque sed, faucibus libero. Vestibulum elementum sodales scelerisque. Nam ullamcorper lacinia odio, a dapibus risus varius sit amet. Cras iaculis enim eu mauris consectetur porta. Vestibulum quis velit non turpis facilisis rutrum vitae eget magna.
                </div>
            </>

        );*/
    }

}
