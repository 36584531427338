import {Component} from "react";
import {setNavigation} from "./NavBar";

export class Contact extends Component{
    static displayName = Contact.name;

    constructor(props) {
        super(props);
        setNavigation("Contact");
    }
    
    render(){
        return <>
            <div
                className="dark:bg-slate-800 rounded-lg py-4 text-center mt-1">
                <span className="dark:text-red-400 text-6xl">Cette page n'est pas encore terminé</span>
                <span className="dark:text-red-500 mt-0.5 block"> Je suis joignable via mon Linkedin.</span>
                <span className="block hidden">Le portfolio a été mis à jour le 24/04/2024</span>
            </div>

        </>
    }
}