import {Component, useState, useEffect} from "react";
import {setNavigation} from "./NavBar";
import {Modal} from "./standalone/ModalForm";
export class Skills extends Component{
    static skillsData = [];
    constructor(props) {
        super(props);
        setNavigation("Skills")
        this.skillsData = [
            {
                "category": "Langages",
                "skills": [
                    {
                        "name": "JavaScript",
                        "logo": "https://upload.wikimedia.org/wikipedia/commons/thumb/6/6a/JavaScript-logo.png/768px-JavaScript-logo.png",
                        "info": "..."
                    },
                    {
                        "name": "C#",
                        "logo": "https://play-lh.googleusercontent.com/uGqP7F-E_eaEwTb3hMz63MWf0YKRSK6n9INBwibBSOrGDg6B3sd-ACuqNrR312ohdQ",
                        "info": "..."
                    },
                    {
                        "name": "PHP",
                        "logo": "https://upload.wikimedia.org/wikipedia/commons/2/27/PHP-logo.svg",
                        "info": "..."
                    },
                    {
                        "name": "C++",
                        "logo": "https://upload.wikimedia.org/wikipedia/commons/1/18/ISO_C%2B%2B_Logo.svg",
                        "info": "..."
                    },
                    {
                        "name": "Python",
                        "logo": "https://upload.wikimedia.org/wikipedia/commons/thumb/c/cf/Python_logo_51.svg/1200px-Python_logo_51.svg.png",
                        "info": "..."
                    },
                    {
                        "name": "SQL",
                        "logo": "https://upload.wikimedia.org/wikipedia/commons/8/87/Sql_data_base_with_logo.png",
                        "info": "..."
                    },
                    
                ]
            },
            {
                "category": "Technologies",
                "skills": [
                    {
                        "name": "React",
                        "logo": "https://cdn.worldvectorlogo.com/logos/react-1.svg",
                        "info": "..."
                    },
                    {
                        "name": "ASP.NET",
                        "logo": "https://play-lh.googleusercontent.com/uGqP7F-E_eaEwTb3hMz63MWf0YKRSK6n9INBwibBSOrGDg6B3sd-ACuqNrR312ohdQ",
                        "info": "..."
                    },
                    {
                        "name": "Symfony",
                        "logo": "https://upload.wikimedia.org/wikipedia/commons/thumb/6/60/Symfony2.svg/800px-Symfony2.svg.png",
                        "info": "..."
                    },
                    {
                        "name": "Laravel",
                        "logo": "https://upload.wikimedia.org/wikipedia/commons/thumb/9/9a/Laravel.svg/1200px-Laravel.svg.png",
                        "info": "..."
                    },
                    {
                        "name": "Node.js",
                        "logo": "https://upload.wikimedia.org/wikipedia/commons/d/d9/Node.js_logo.svg",
                        "info": "..."
                    },
                    
                ]
            }
        ];
    }

    openSkillModal = (skill)=>{
        //Still working on it....
        Modal.Open(skill.name, skill.info);
    }
    
    render() {
        return (
            <div className="p-8">
                {
                    this.skillsData.map((category, index) => (
                    <div key={index} className="mb-10">
                        <h2 className="text-2xl font-bold mb-6">{category.category}</h2>
                        <div className="flex flex-wrap gap-6 justify-start items-center">
                            {
                                category.skills.map((skill, skillIndex) => (
                                    <div key={skillIndex} className="group" onClick={()=> 0/*this.openSkillModal(skill)*/} data-dialog-target="dialog">
                                        <div className="w-24 h-24 bg-gray-100 flex items-center justify-center overflow-hidden rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300 ease-in-out">
                                            <img src={skill.logo} alt={skill.name + " logo"} className="group-hover:scale-125 transition-transform duration-300 ease-in-out" />
                                        </div>
                                        <p className="text-center mt-2 group-hover:text-lg transition-all duration-300 ease-in-out">{skill.name}</p>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                    ))
                }
            </div>
        );
    }
}